.banner {
    width: calc(100% - 90px);
    margin: 20px auto 15px;
    /*height: fit-content;*/
    max-width: 1320px;
    border-radius: 24px;
    position: relative;
}

.image>img {
    height: 100%;
    width: 100%;
    max-width: 1320px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
}

.backBtn {
    position: absolute;
    top: calc(50% - 15px);
    left: -37px;
}

.backBtn div,
.nextBtn div {
    background: #f2f2f2;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.nextBtn {
    position: absolute;
    top: calc(50% - 15px);
    right: -37px;
}

.nextBtn div img {
    transform: rotate(180deg);
}

@media (max-width: 1439px) {
    .banner {
        max-width: calc(100% - 160px);
    }

    .image>img {}

    .backBtn {
        position: absolute;
        top: calc(50% - 10px);
        left: -25px;
    }

    .backBtn div,
    .nextBtn div {
        height: 20px;
        width: 20px;
    }

    .nextBtn {
        position: absolute;
        top: calc(50% - 10px);
        right: -25px;
    }

    .backBtn img,
    .nextBtn img {
        height: 10px;
        width: 6px;
    }
}

.App {
    height: 100%;
}

@media (max-width: 1024px) {
    .banner {
        margin: 0 auto;
        width: calc(100vw - 32px);
        height: 100%;
        max-height: initial;
    }

    .image {
        max-height: calc(100% - 30px);
        height: calc(100% - 30px);
    }

    .image>img {
        height: 100%;
        max-height: initial;
    }
}
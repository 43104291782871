.selectorShape {
  width: 230px;
  height: 38px;
  background: rgba(118, 118, 128, 0.12);
  padding: 3px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.ecoBallsBtn {
  border-radius: 10px;
  height: 32px;
  width: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.activeBtn {
  background: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
}

.ecoBalls {
  background: linear-gradient(120.5deg, #f29a78 14.82%, #f26c37 48.99%, #f15e22 91.11%);
  border-radius: 8px;
  height: 20px;
  width: fit-content;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleBG {
  height: 32px;
  width: 51px;
  background: #ededed;
  border-radius: 18px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  cursor: pointer;

  .toggle {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: #fff;
  }
}

.ecoTextWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ecoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;

  @media (min-width: 1024px) {
    padding: 0;
    margin-bottom: 16px;
    gap: 24px;
    width: fit-content;
    min-width: 257px;
  }
}

.grayLine {
  background: #F7F7F7;
  height: 2px;
  width: 100%;
  margin-bottom: 20px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 22px;

  @media (max-width: 1023px) {
    flex-wrap: nowrap;
    gap: 0;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

.paymentCard {
  width: 170px;
  padding: 8px;
  border-radius: 12px;
  background: #f5f5f5;
  cursor: pointer;
  height: 75px;

  @media (max-width: 1023px) {
    margin-left: 20px;
    min-width: 170px;
  }
}

.paymentCard:last-child {
  @media (max-width: 1023px) {
    margin-right: 20px;
  }
}

.activePayment {
  background: #fff;
  border: 1px solid #43B02A;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
}

.promoWrapper {
  height: 49px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
}

.promoInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  input {
    height: 49px;
    width: 220px;
    background: #f7f7f7;
    padding: 11px 15px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    line-height: 14px;
    color: #383838;

    @media (max-width: 1023px) {
      width: 100%;
    }

    ::placeholder {
      font-size: 14px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      line-height: 14px;
      color: #9d9d9d;
    }


  }

  @media (max-width: 1023px) {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  div {
    height: 49px;
    width: 115px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #43b02a;
    cursor: pointer;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  cursor: pointer;

  @media (min-width: 1024px) {
    max-width: 257px;
    min-width: 257px;
    padding: 5px 0;
  }
}

.submitBtn {
  height: 48px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  margin: 0 15px;
  cursor: pointer;
  background: #43b02a;
}

.errorPromo {
  margin-left: 16px;
  position: absolute;
  bottom: -20px;
}

.modal {
  background: #fff;
  border-radius: 5px;
  padding: 25px 33px 38px 37px;
  position: relative;
}

.closeModal {
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
}

.promoInputDesk {
  width: 220px;
  height: 50px;
  border: 1px solid #c1c1c1;
  border-radius: 12px;
  padding: 7px 30px 7px 14px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  position: relative;

  input {
    width: 220px;
  }
}

.clearPromo {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
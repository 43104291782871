/*Bonuses*/
.container {
  padding: 0 14px 100px;
}

.goBack {
  width: fit-content;
  padding: 30px 0;
  position: absolute;
  top: -5px;
  left: 25px;
}

.ecoRectangle {
  background: linear-gradient(120.5deg, #f29a78 14.82%, #f26c37 48.99%, #f15e22 91.11%);
  border-radius: 8px;
  height: 80px;
  width: 148px;
  padding: 11px 15px 13px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  font-family: 'DINPro-Regular', sans-serif;
}

.extraBonusesModal {
  padding: 27px 22px 33px;
  background: #fff;
  border-radius: 12px;
  position: relative;
  overflow-y: scroll;

  img {
    width: 100%;
  }
}

.ecoFire {
  padding: 0 15px;

  >div {
    display: flex;
    gap: 9px;
    align-items: center;

    >div {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: #000;
    }
  }
}

.header {
  display: flex;
  position: sticky;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffffee;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 0;
  margin: 0 -14px;

  >div {
    margin: 0 auto;
  }
}


/*Personal*/
.inputFile {
  margin-bottom: 31px;
  border-bottom: 1px solid #9d9d9d;
  width: fit-content;
  cursor: pointer;
  position: relative;

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-family: 'DINPro-Medium', sans-serif;
    color: #9D9D9D;
  }
}

.inputRow {
  max-width: 692px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  input {
    width: 100%;
    max-width: 335px;
    height: 52px;
    padding: 16px 14px;
    background: #f7f7f7;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      color: #9d9d9d;
    }
  }
}

.unpaidOrders {
  font-size: 14px;
  font-weight: 400;
  font-family: 'DINPro-Regular', sans-serif;
  line-height: 16px;
  color: #383838;
}


.modal {
  width: 100%;
  padding: 0 15px 30px;
  max-width: 480px;
}

.buttons {
  width: 100%;
  max-width: 345px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.mainBtn {
  border-radius: 12px;
  background: #43b02a;
  flex: 2.5;
  height: 44px;
  padding: 6px 8px 5px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .mainBtnText {
    font-size: 13px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    line-height: 16px;
    color: #fff;
    max-width: 150px;
    text-align: center;
  }

  .timer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 6px;
    height: 24px;
  }
}

.skipBtn {
  border-radius: 12px;
  background: #9f9f9f;
  flex: 1;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
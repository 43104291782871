.stickyPart {
    background-color: transparent;
    position: sticky;
    overflow: hidden;
    top: 0;
    display: flex;
    flex-direction: column;
    z-index: 3
}

@media (max-width: 1024px) {
    .stickyPart {
        justify-content: center;
    }
}

.sectionHeaderContainer {
    justify-content: center;
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 8px
}

.title {
    font-size: 22px;
    font-weight: 700;
    font-family: 'DINPro-Bold', sans-serif;
    color: #383838;
}

.subTitle {
    font-size: 20px;
    font-weight: 700;
    font-family: 'DINPro-Bold', sans-serif;
    color: #929292;
}

.inputBtn {
    height: 50px;
    width: 100%;
    background: #eeeeef;
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 15px;
    padding: 0 14px;
    max-width: 375px;
}

.inputBtnWrapper {
    padding: 0 16px;
    display: flex;
    justify-content: center;
}

@media (max-width: 1024px) {

    .inputBtn,
    .inputBtnWrapper {
        width: 100%;
        max-width: none;
    }
}

.initPage {
    max-width: 1602px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.initPage h1 {
    color: #000;
    font-weight: 400;
    text-transform: none;
    font-family: DINPro;
    font-size: 46px;
    width: 60%;
    text-align: center;
    line-height: 51px;
    padding: 0 40px;
}

.initPage img {
    height: 80px;
    margin-left: -40px;
}

@media (max-width: 1024px) {
    .initPage {
        max-width: calc(100% - 160px);
    }
}
.wrapper {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'DINPro-Regular', sans-serif;
  max-width: 1602px;
  margin: 0 auto 50px;

  @media (max-width: 1800px) {
    max-width: calc(100% - 160px);
  }
}

.wrapper img {
  height: 16px;
}

.numberLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  margin-bottom: 30px;
  background: #eaeaea;
  padding: 4px;
}

.form {
  padding: 20px;
  max-width: 470px;
  background: #eaeaea;
  border-radius: 4px;
}

.formTitle {
  font-size: 16px;
  font-weight: 500;
  font-family: 'DINPro-Regular', sans-serif;
  margin-bottom: 35px;
  color: #43b02a;
  line-height: 18px;
}

.inputGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 45px 45px;
  grid-gap: 15px;
  margin-bottom: 35px;

  input {
    padding: 0 10px;
    border-radius: 12px;
  }


}

.invalid {
  border: 1px solid #F03517;
  color: #F03517;
}

.submitBtn {
  button {
    height: 50px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    background: #43b02a;
    width: 200px;
    font-size: 16px;
    font-family: 'DINPro-Regular', sans-serif;
    font-weight: 500;
    color: white;
  }
}

.header {
  display: flex;
  position: sticky;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffffee;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 0;
  margin: 0 -14px;

  >div {
    margin-left: 50px;
  }
}

.goBack {
  width: fit-content;
  padding: 30px 0;
  position: absolute;
  top: -5px;
  left: 25px;
}

.container {
  padding: 0 15px;
}
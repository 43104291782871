.container {
  max-width: 1602px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1800px) {
    max-width: calc(100% - 160px);
  }
}

.textContainer {
  max-width: 1602px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  @media (max-width: 1800px) {
    max-width: calc(100% - 160px);
  }
}

.productCard {
  width: calc(100% / 7 - 10px);
  margin: 5px;

  @media (max-width: 1000px) {
    width: calc(100% / 5 - 10px);
  }

  @media (max-width: 720px) {
    width: calc(100% / 3 - 10px);
  }
}
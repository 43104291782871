.bonusesWrapper {
  background: #fff;
  border-radius: 12px;
  padding: 25px 36px;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;
}

.ecoRectangle {
  background: linear-gradient(120.5deg, #f29a78 14.82%, #f26c37 48.99%, #f15e22 91.11%);
  border-radius: 5px;
  height: 99px;
  width: 180px;
  padding: 11px 15px 13px;

}

.ecoFire {
  >div {
    display: flex;
    gap: 9px;
    align-items: center;

    >div {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: #000;
    }
  }
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  font-family: 'DINPro-Regular', sans-serif;
}

.codeWrapper {
  background: #F3F2EE;
  border-radius: 12px;
  padding: 17px 28px 14px 24px;
  width: 100%;
  max-width: 541px;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    padding: 19px 14px 15px 11px;
    max-width: 347px;
  }
}

.copyBtn {
  background: #383838;
  border-radius: 24px;
  width: 150px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.shareBtn {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #383838;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.copyInputBtn {
  position: absolute;
  top: 16px;
  left: 222px;
  cursor: pointer;
}

.codeHolder_order {
  padding: 15px;

  @media (max-width: 1023px) {
    .codeWrapper {
      max-width: 100%;

      input {
        width: auto;
        flex: 1;
        margin-right: 15px;
        padding: 0px 15px;
        max-width: calc(100% - 59px);
      }
    }

    .copyInputBtn {
      left: auto;
      right: 77px;
    }
  }
}

.shareContainer {
  display: flex;
  gap: 5px;
  background: #fff;
  width: 220px;
  position: absolute;
  border-radius: 22px;
  top: 56px;
  left: 0;
  justify-content: center;
  align-items: center;
  height: 52px;
}

.codeWrapper input {
  width: 319px;
  height: 52px;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 0 17px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  font-family: 'DINPro-Black', sans-serif;

  @media (max-width: 1023px) {
    width: 258px;
    padding: 0 55px 0 11px;
  }
}

.anketaWrapper {
  background: #F3F2EE;
  border-radius: 12px;
  padding: 22px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 541px;
  margin-bottom: 20px;

  @media (max-width: 1023px) {
    max-width: 347px;
    padding: 17px 13px 17px 11px;
  }
}

.anketaBtn {
  background: #FF7834;
  border-radius: 24px;
  height: 45px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.bonuses_range {
  position: relative;
  max-width: 500px;
}

.range_prices_holder {
  display: flex;
  justify-content: space-between;
}

.range_prices_holder span {
  flex: 1;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #9C9C9C;
}

.range_prices_holder.bottom {
  margin-top: 3px;
}

input[type='range'] {
  width: 100%;
  -webkit-appearance: none;
  background-color: #43B02A;
  z-index: 2;
  position: relative;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 2px;
  -webkit-appearance: none;
  color: #43B02A;
}

input[type='range']::-webkit-slider-thumb {
  width: 26px;
  -webkit-appearance: none;
  height: 26px;
  background: #43B02A;
  border: solid 7px #FFF;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: -13px;
  margin-left: 12px;
  cursor: pointer;
}

input[type="range"]::-moz-range-progress {
  background-color: #43B02A;
}

input[type="range"]::-moz-range-track {
  background-color: #43B02A;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: #43B02A;
}

input[type="range"]::-ms-fill-upper {
  background-color: #43B02A;
}

.range_dots_holder {
  display: flex;
  justify-content: space-around;
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 26px;
}

.range_dots_holder span {
  height: 4px;
  width: 4px;
  background: #43B02A;
  border-radius: 50%;
}

.bottom {
  margin-top: 3px;
}

.top {
  margin-bottom: 3px;
}

.sliderWrapper {
  background: #F3F2EE;
  border-radius: 12px;
  padding: 25px 20px;
  max-width: 541px;
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    max-width: 347px;
  }
}

.extraBonusesModal {
  padding: 27px 22px 33px;
  background: #fff;
  border-radius: 12px;
  width: 544px;
  position: relative;
  max-height: 708px;
  overflow-y: scroll;

  img {
    width: 100%;
  }
}

.closeModalHed {
  cursor: pointer;
  top: 10px;
  position: absolute;
  right: -30px;
}

.closeModal {
  cursor: pointer;
  margin-top: 10px;
}

.extraBonusesModal::-webkit-scrollbar-track {
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapper {
    margin-top: 50px;
    width: 100%;
    background: #FFF;
    border-top: 1px solid #e7e7e7;
}

@media (max-width: 1024px) {
    .wrapper {
        height: auto;
    }
}

.container {
    max-width: 1602px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

}

@media (max-width: 1800px) {
    .container {
        max-width: calc(100% - 160px);
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        padding: 0px 16px;
    }
}

.logoContent {
    margin-top: 14px;
}

@media (max-width: 1024px) {
    .logoContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 500px) {
    .logoContent {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.socials {
    gap: 32px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
}

.socials img {
    max-height: 15px;
    max-width: 19px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin-top: 29px;
    width: 100%;
}

@media (max-width: 1024px) {
    .info {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 100%;
        margin-bottom: 80px;
    }
}

.QR {
    display: flex;
    gap: 20px;
}

@media (max-width: 1024px) {
    .QR {
        justify-content: center;
        width: 100%;
        padding-bottom: 50px;
    }
}

.mobileCopyright {
    text-align: center;
    margin-bottom: 50px;
    width: 100%;
}

.QRtext {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-family: 'DINPro-Medium', sans-serif;

    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 122px;
    text-align: right;
    margin-top: 29px;
}

.QRphone {
    position: relative;
}

.screen {
    position: absolute;
    top: 10px;
    left: 10px;
}

.QRimg {
    position: absolute;
    top: 40px;
    left: 45px;
}
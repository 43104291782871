.container {
    width: 100vw;
    max-width: 480px;
    height: calc(100% - 5vh);
    background-color: #ffffff;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition-duration: .2s;
    opacity: 0;
    transform: translateY(40px);
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateY(40px)
}

.mounted {
    opacity: 100%;
    transform: translateY(0);
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateY(0px)
}

.contentContainer::-webkit-scrollbar-thumb {
    background-color: #909090;
    border-radius: 10px;
    width: 0;
    border: 0 solid #fff;
}

.contentContainer::-webkit-scrollbar {
    width: 0;
}

.backdrop {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.contentContainer {
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 1;
}
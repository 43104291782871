.modal {
    background: #fff;
    border-radius: 5px;
    width: 843px;
    padding: 40px 40px 50px 35px;
    max-height: 85vh;
    overflow-y: scroll;
}


.modal::-webkit-scrollbar-track {
    background: transparent;
    max-height: 85%;
    height: 100px;
    margin: 20px 0;
}

.closeModal {
    padding: 9px;
}

.closeModal img {
    cursor: pointer;
}



.deliveryPriceInfo {
    width: 100%;
    max-width: 465px;
    margin-bottom: 7px;
}

.deliveryPriceRow {
    max-width: 325px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.grayLine {
    height: 1px;
    width: 100%;
    background: #eee;
    margin-bottom: 15px;
}

.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    font-family: 'DINPro-Light', sans-serif;
    color: #383838;
    margin-bottom: 12px;
}
.headerInfoWrapper {
  border-bottom: 1px solid #e7e7e7;
}

.headerInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 70px;
  height: var(--header-info-desktop-height);
  max-width: 1602px;
  margin: 0 auto;

  @media (max-width: 1800px) {
    max-width: calc(100% - 50px);
  }
}

.headerContentWrapper {
  height: var(--header-desktop-height);
  background: #ffffff;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #e7e7e7;
}

.headerContent {
  align-items: center;
  display: flex;
  max-width: 1602px;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;

  position: relative;

  @media (max-width: 1800px) {
    max-width: calc(100% - 160px);
  }
}

.userAbsolute {
  position: absolute;
  top: 28px;
  left: -68px;
}

.user {
  background: #ebebeb;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  min-width: 44px;
  width: 44px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}

.balls {
  position: absolute;
  background: linear-gradient(120.5deg, #f29a78 14.82%, #f26c37 48.99%, #f15e22 91.11%);
  border-radius: 8px;
  top: -5px;
  left: 21px;
  width: fit-content;
  min-width: 30px;
  padding: 3px 4px 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  user-select: none;
  cursor: pointer;
  width: 110px;
  margin-right: 30px;

  .logoImage {
    height: 40px;
  }

  @media (max-width: 1439px) {
    margin-right: 23px;
  }
}

.inputWrapper {


  @media (max-width: 1439px) {}
}

.input {
  position: relative;
  width: 100%;

  input {
    height: 48px;
    width: 100%;
    background: #ebebeb;
    border-radius: 8px;
    padding: 15px 90px 15px 15px;
    font-family: 'DINPro-Medium', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #383838;
  }

  .verticalLine {
    width: 1px;
    height: 34px;
    background: #d3d3d3;
    position: absolute;
    top: 6px;
    right: 46px;
  }

  .closeSearch {
    position: absolute;
    top: 0;
    right: 47px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 48px;
    user-select: none;
  }

  .search {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 48px;
    user-select: none;
  }

  .search img,
  .closeSearch img {
    -webkit-user-drag: none;
  }

}

.input input::placeholder {
  font-family: 'DINPro-Medium', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #383838;
}

.searchBtn {
  background: #383838;
  height: 44px;
  width: 44px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-right: 23px;
  flex-shrink: 0;

  @media (max-width: 1400px) {
    margin-right: 5px;
  }
}

.catalogue {
  display: flex;
  gap: 11px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-right: 23px;
  width: 250px;

  div {
    background: #000;
    height: 44px;
    width: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
  }
}

.address {
  cursor: pointer;
  user-select: none;
  margin-right: 25px;
  width: 260px;
}

.addressText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.containerOrder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.modalWrapper {
  padding: 0 20px 200px;
  max-width: 425px;
}

.agreements {
  font-size: 10px;
  line-height: 11px;
  max-width: 440px;
  margin: 0 auto;
  margin-top: 1rem;
  color: #9c9c9c;
  text-align: left;

  a {
    border-bottom: 1px solid #d9d9d9;
    color: #9c9c9c;
  }
}

.callBtn {
  width: 100%;
  max-width: 420px;
  height: 49px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: 'DINPro-Medium', sans-serif;
  cursor: pointer;
  margin: 0 auto 25px;

  @media (max-width: 700px) {
    width: 90%;
  }
}

.toggleBG {
  height: 32px;
  width: 51px;
  background: #ededed;
  border-radius: 18px;
  display: flex;
  align-items: center;
  padding: 0 2px;

  .toggle {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: #fff;
  }
}

.codeInputWrapper {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 14px 28px;
  width: fit-content;
}

.cell {
  display: flex;
  width: 10px;
  height: 27px;
  align-items: center;
  justify-content: center;
  margin: 0 11px;
  position: relative;
}

.codeFieldRoot {
  display: flex;
  justify-content: center;
}

.dot {
  background-color: #7f7f7f;
  width: 7px;
  height: 2px;
  transition-duration: .3s;
  transform: scale(1);
  animation: none;
}

.caretBlink {
  animation: jop 1s infinite;
}

@keyframes jop {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.caret {
  background-color: #7f7f7f;
  width: 1px;
  height: 34px;
  position: absolute;
  right: 15px;
  opacity: 0;
}

.invisibleInput {
  opacity: 0;
  width: 10px;
  height: 10px;
  position: fixed;
  top: -20px;
}

.input {
  background-color: #f7f7f7;
  height: 50px;
  padding: 0 30px;
  border-radius: 8px;
  border: none;
  outline: none;
  width: 100%;
  max-width: 335px;
  align-self: center;
  margin-bottom: 26px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bdbdbd;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bdbdbd;
}

.backBtn {
  position: absolute;
  top: 0;
  left: 24px;
  width: 47px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: 16px;
  background: #f2f2f2;
  cursor: pointer;

  @media (min-width: 1024px) {
    top: 26px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  margin: 0 auto 63px;
  width: 100%;


}

.orderTick {
  display: flex;
  align-items: center;
  gap: 8px;
}

.orderNumber {
  background: #f7f7f7;
  border-radius: 4px;
  padding: 1px 5px;
}

.switchNumber {
  padding-bottom: 2px;
  border-bottom: 1px solid #bfbfbf;
  cursor: pointer;
  margin-left: 7px;
}

.phoneNumberBlock {
  text-align: center;
  margin-bottom: 17px;
}

.refreshAndWa {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  margin-bottom: 22px;
}

.refreshAndWaBtn {
  text-align: center;
  cursor: pointer;
}

.circle {
  height: 56px;
  width: 56px;
  background: #f7f7f7;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin: 0 auto 6px;
}

.hr {
  position: relative;
  display: block;
  margin: 1rem 0;
  text-align: center;
  padding: 0 .5rem;
  color: #A3A3A1;

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: .5rem;
    left: 0;
    background-color: #D9D9D9;
    width: 44%;
    height: 1px;
  }

  &::after {
    right: 0;
    left: auto;
  }
}

@media (max-width:760px) {
  button.callBtn {
    margin-top: 19rem !important;
    margin-top: 10svh !important;
  }

  .agreements {
    margin-top: 7rem;
  }
}

.cnt-info {}
.modal {
  max-height: 812px;
  height: 100%;
  width: 100vw;
  max-width: var(--modal-max-width);
  background: #fff;
  border-radius: 5px 5px 0 0;
  position: fixed;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  @media (max-width: 1023px) {
    max-height: 90vh;
  }
}

.header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: 24px;
  right: 20px;
}

.closeBtn img {
  height: 18px;
}

.messagesContainer {
  padding: 0 15px 25px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.emptyChat {
  padding: 100px 15px 25px;
  overflow-y: scroll;
  max-height: calc(100% - 140px);
  height: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 143px;
    height: 149px;
  }
}

.footer {
  background: #F6F6F6;
  box-shadow: 0px -0.33px 0px #A6A6AA;
  padding: 7px 15px 7px;

}

.footerContent {
  width: 100%;
  display: grid;
  grid-gap: 13px;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;

  /*  div {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    textarea {
      border-radius: 16px;
      background: #fff;
      border: 1px solid #8e8e93;
      height: 100%;
      width: 100%;
      padding: 7px 18px;
      color: #383838;
      font-size: 14px;
      font-weight: 500;
      line-height: 18;
      font-family: 'DINPro-Medium', sans-serif;
      resize: none;
      outline: none;
      white-space: pre-line;

      ::placeholder {
        color: #bdbdbd;
      }
    }
  }*/
}

.footerContent textarea {
  border-radius: 16px;
  background: #fff;
  border: 1px solid #8e8e93;
  padding: 7px 18px;
  color: #383838;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  font-family: 'DINPro-Medium', sans-serif;
  resize: none;
  outline: none;
  white-space: pre-line;

  ::placeholder {
    color: #bdbdbd;
  }
}

.messageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 13px 15px 28px;
  position: relative;
  width: fit-content;
  max-width: 285px;
  background: #abf69e;
  margin-bottom: 10px;
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  min-width: 40px;
}

.messageTime {
  position: absolute;
  bottom: 7px;
  right: 5px;
}

.messageDate {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.managerinfo {
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
}

.messageImg {
  display: flex;
  justify-content: center;

  img {
    object-fit: cover;
    height: 255px;
    width: 255px;
    cursor: pointer;
    margin-bottom: 10px;
  }
}

.inputFile {
  img {
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.loadedImg {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 8px 20px 5px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background: #F6F6F6;

  span {
    max-width: 300px;
    width: 300px;
    overflow: hidden;
  }

  img {
    cursor: pointer;
  }
}
.cardWrapper {
  //min-width: 170px;
  max-width: 245px;
  width: 100%;
  padding: 6px 6px 12px;
  border-radius: 5px;
  user-select: none;
  margin: 0 auto;
  height: 100%;
  cursor: pointer;
  position: relative;

  @media (max-width: 1025px) {
    padding: 4px 4px 12px;
  }

  @media (max-width: 1024px) {}

  @media (max-width: 400px) {}
}

.slimCardWrapper {
  width: 100%;
  padding: 6px;
  border-radius: 12px;
  user-select: none;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
}

.pCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.pCardSlim {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .pGrow {
    flex-grow: 1;
    padding-right: 5px;
  }

  .imageWrapper {
    margin-bottom: 0px;
  }

  .imageWrapper>img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 4px;
    margin-bottom: 0px;
    position: static;
  }

  .btn {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
  }

  .btnHolder {
    position: relative;
    flex-shrink: 0;

    .absoluteController {
      position: absolute;
      right: 0px;
      top: 0px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      background: #fff;
      border-radius: 12px;
      width: 98px;

      .amountToggle {
        width: 100%;
      }

      .plusBtn,
      .minusBtn,
      .btn {
        box-shadow: none;
      }
    }
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 3px;
    min-height: auto;
    margin-top: 10px;

    a {
      font-size: 14px;
      line-height: 15px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      color: #383838;
    }
  }
}

.discountBadge {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
  height: 30px;
}

.imageWrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 0px;
  padding-bottom: 100%;
  margin-bottom: 2px;
}

.imageWrapper>img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.rating {
  display: flex;
  gap: 4px;
  position: absolute;
  right: 5px;
  bottom: 8px;
  align-content: center;
}

.toggles {
  height: 94px;
  width: 42px;
  background: #f8f8f8;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px 8px 0 8px;
  cursor: pointer;
}

.toggle {
  height: 47px;
  width: 42px;
  color: #c6c6c6;
  font-weight: 500;
  font-family: 'DINPro-Medium', sans-serif;
  line-height: 16px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeToggle {
  background: #fff;
  color: #383838;
  font-weight: 700;
  font-family: 'DINPro-Bold', sans-serif;
  height: 47px;
  width: 42px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  ;
}

.wholesaleDiscount {
  color: #ff7834;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  font-family: 'DINPro-Bold', sans-serif;
}

.weightAndOldPrice {
  display: flex;
  align-items: center;
  padding: 0 2px;
}

.oldPrice {
  position: relative;
}

.oldPrice:before {
  content: '';
  display: block;
  height: 1px;
  width: calc(100% + 3px);
  background: #bfbfbf;
  position: absolute;
  top: 50%;
  left: -2px;
}

.btn {
  width: calc(100% - 10px);
  height: 44px;
  margin: 0 auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;


  @media (min-width: 1025px) {
    height: 44px;
  }

  @media (max-width: 1024px) {
    height: 38px;
  }

  @media (max-width: 800px) {
    height: 32px;
    width: calc(100% - 8px);
  }

}

.btnOldPrice {
  position: relative;
}

.btnOldPrice:before {
  content: '';
  display: block;
  height: 1px;
  width: calc(100% + 7px);
  background: #ff7834;
  position: absolute;
  top: 50%;
  left: -3px;
}

.amountToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 10px);
  margin: 0 auto;
  flex-shrink: 0;

  @media (max-width: 800px) {
    width: calc(100% - 8px);
  }
}

.ed_izm {
  @media (max-width: 800px) {
    display: none;
  }
}

.minusBtn,
.plusBtn {
  height: 44px;
  width: 44px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;

  @media (min-width: 1025px) {
    height: 44px;
    width: 44px;
  }

  @media (max-width: 1024px) {
    height: 38px;
    width: 38px;
  }

  @media (max-width: 800px) {
    height: 32px;
    width: 32px;
  }
}

.minusBtn img,
.plusBtn img {
  width: 14px;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 3px;
  min-height: 33px;
  padding: 0 2px;
  margin-top: 10px;

  a {
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;
  }
}

.badgeText {
  margin-right: 5px;

  &:last-of-type {
    margin-right: 0px;
  }
}

.dayBadge {
  width: 60px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 0;
}